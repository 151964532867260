const neighborhoods = require('./data/neighborhoods.json');

export const FB_FUNCTION_URL = "https://us-central1-villavox-a5e76.cloudfunctions.net";
export const GOOGLE_API_KEY = "AIzaSyDvvN5o5cHBpBOMmWD577RpdMUKjV921KQ";

export const pointCoords = [
    {
        areaKey: 'A',
        lat: 47.756,
        lng: -122.3457,
        cities: [
            'Bothell',
            'Kenmore',
            'Shoreline',
            'Woodinville',
            'Lynwood',
            'Mill Creek',
            'Marysville',
            'Everett',
            'Kirkland'
        ]
    },
    {
        areaKey: 'B',
        lat: 47.6062,
        lng: -122.3321,
        cities: [
            'Seattle'
        ]
    },
    {
        areaKey: 'C',
        lat: 47.415139,
        lng:  -122.240232,
        cities: [
            'Burien',
            'Des Moines',
            'Kent',
            'Federal Way',
            'Normandy Park',
            'Renton',
            'SeaTac',
            'Tukwila',
            'Spanaway',
            'Tacoma',
            'Sumner',
            'Vancouver',
            'Bellevue',
            'Newcastle'
        ]
    },
    {
        areaKey: 'D',
        lat: 47.6085,
        lng: -122.13,
        cities: [
            'Bellevue',
            'Issaquah',
            'Kirkland',
            'Mercer Island',
            'NewcastleRedmond',
            'Sammamish',
            'Renton',
            'Bothell'
        ]
    }
]

const stateAcronym = "WA";  // Currently the neighborhood data is only for Washington
const getCombos = () => {
    const combinations = [];
    let citiesSeen = new Set();
    let neighborhoodsSeen = new Set();
    for (let i = 0; i < neighborhoods.length; i++) {
        let currentNeighborhood = neighborhoods[i].fields;
        let name = currentNeighborhood.name;
        let city = currentNeighborhood.city;
        if (!citiesSeen.has(city)) {
            //combinations.push(`${city}, ${stateAcronym}`);
            citiesSeen.add(city);
        } else if (!neighborhoodsSeen.has(name)) {
            combinations.push(`${name}, ${city}, ${stateAcronym}`);
        }
    }
    // Edge case with queen anne
    combinations.push("Queen Anne, Seattle, WA");
    //combinations.push("Queen Anne, WA");

    return combinations;
}

export const all_neighborhood_city_combinations = getCombos();
