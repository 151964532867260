import React from 'react';
import './Share.css';

const Share = () => {
    return (
        <div id="iframeWrapper">
            <iframe id="typeform-full" width="100%" height="100%" frameBorder="0"
                src="https://formsubmissionpage.typeform.com/to/beP5em"></iframe>
        </div>
    );
}

export default Share;