import React from 'react';
import { useEffect, useState } from 'react';
import './Marker.css';

const Marker = (props) => {
    const [selected, setSelected] = useState(props.selected);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    return (
        <div className="markerWrapper" onClick={() => { props.onClick(props.id) }}>
            <div className={"outer pulse " + (selected ? 'selected' : '')} />
            <div className={"inner " + (selected ? 'selected' : '')} />
        </div>
    );
  };

  export default Marker;