import React, { useState, useEffect, useRef } from "react";
import MicroModal from "micromodal";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router";
import TopNav from "../TopNavigation/TopNav.jsx";
import FilterBar from "../FilterBar/FilterBar.jsx";
import SinglePost from "../RightPanel/SinglePost.jsx";
import PreviewPost from "../RightPanel/PreviewPost.jsx";
import GoogleMap from "../LeftPanel/GoogleMap.jsx";

import { FB_FUNCTION_URL } from "../const";
import axios from "axios";
import FirebaseSetup from "../scripts/FirebaseSetup";
import firebase, { database } from "firebase/app";
import "firebase/auth";
import "@firebase/storage";
import "firebase/database";
import checkDate from "../scripts/checkDate";

import "../app.css";
import { Link } from "react-router-dom";

const PREVIEW_WORD_LIMIT = 40;
const neighborhoods = require("../data/neighborhoods.json");

const Dashboard = (props) => {
  const { uniqueUrl } = useParams();
  const history = useHistory();

  // Hooks used for fetch requests to get post results (-1 is default)
  const [posts, setPosts] = useState([]);
  const [homeType, setHomeType] = useState(-1);
  const [dateSold, setDateSold] = useState(-1);
  const [lowPrice, setLowPrice] = useState(0);
  const [highPrice, setHighPrice] = useState(2000000);
  const [soldPrice, setSoldPrice] = useState(-1); // Not really used but necessary for current api call
  const [previewScrollPosition, setPreviewScrollPosition] = useState(0);
  const [bodyContentScrollPosition, setBodyContentScrollPosition] = useState(0);
  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    setOpen(true);
  };

  // Responsible for storing images as a map (id - images)
  const [postImages, setPostImages] = useState(null);

  const [postID, setPostID] = useState(-1);

  // Create array of preview posts
  const [previewPosts, setPreviewPosts] = useState([]);

  // User account. Empty array represents no authenticated user
  const [user, setUser] = useState([]);

  // Get and cache the firebase image data onto the app
  const [agentImages, setAgentImages] = useState([]);

  const [posterImages, setPosterImages] = useState([]);

  const [cities, setCities] = useState([]);

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const getPostId = () => {
    if (uniqueUrl && posts) {
      const post = posts.find((post) => {
        const url = (post.city + " " + post.title)
          .toLowerCase()
          .split(" ")
          .join("-")
          .replace(/[&\/\\#,+()$~%.'":*?!<>{}]/g, "");
        return uniqueUrl === url;
      });
      return (post && post.PostID) || -1;
    } else {
      return -1;
    }
  };

  const getUniqueUrl = (postId) => {
    if (posts) {
      const post = posts.find((post) => post.PostID == postId);
      return (post.city + " " + post.title)
        .toLowerCase()
        .split(" ")
        .join("-")
        .replace(/[&\/\\#,+()$~%.'":*?!<>{}]/g, "");
    } else {
      return null;
    }
  };

  const initFirebase = () => {
    // Initialize the firebase user authentication
    if (!firebase.apps.length) {
      firebase.initializeApp(FirebaseSetup.firebaseConfig);
      const storage = firebase.storage().ref();
      const agents = [];
      const posters = [];
      storage
        .child("img/agent")
        .listAll()
        .then((res) => {
          res.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              agents.push(url);
            });
          });
          setAgentImages(agents);
        });

      storage
        .child("img/poster")
        .listAll()
        .then((res) => {
          res.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              posters.push(url);
            });
          });
          setPosterImages(posters);
        });

      // Pull and store post image links
      let finalMap = new Map();
      storage
        .child("img/posts")
        .listAll()
        .then((references) => {
          references.prefixes.forEach((ref) => {
            const folderName = ref.location.path_;
            storage
              .child(folderName)
              .listAll()
              .then((res) => {
                res.items.map(async (item) => {
                  const url = await item.getDownloadURL();
                  // Pull post number
                  const splitFilePath = folderName.split("/");
                  const postNumber = parseInt(splitFilePath[2]);
                  let currentUrls = finalMap.get(postNumber);
                  if (currentUrls) {
                    currentUrls.push(url);
                    finalMap.set(postNumber, currentUrls);
                  } else {
                    finalMap.set(postNumber, [url]);
                  }

                  if (finalMap.size === references.prefixes.length) {
                    setPostImages(finalMap);
                  }
                });
              });
          });
        })
        .catch((err) => {
          throw new Error("Failure to pull post images from storage: " + err);
        });
    }
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .database()
          .ref(`Users/${user.uid}`)
          .once('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
              setUser({ ...user, name: `${data.firstName} ${data.lastName}` });
            } else {
              setUser(user);
            }
          });
      }
    });
  };

  function fetchPosts() {
    let filters = {
      location: -1,
      type: homeType,
      date: dateSold,
      price: soldPrice,
    };
    axios
      .get(FB_FUNCTION_URL + "/getPostData", {
        params: filters,
      })
      .then((res) => {
        setPosts(res.data);
      });
  }

  useEffect(() => {
    initFirebase();
    fetchPosts();
  }, []);

  useEffect(() => {
    setPostID(getPostId());
    handleLocation(null);
  }, [posts, postImages]);

  useEffect(() => {
    const singlePost = document.getElementById("singlePost");
    const bodyContent = document.getElementById("bodyContent");
    const rightPanel = document.getElementById("rightPanel");
    if (postID == -1) {
      if (isMobile) {
        bodyContent.scrollTop = bodyContentScrollPosition;
      } else {
        rightPanel.scrollTop = previewScrollPosition;
      }
      handleLocation(null);
    } else {
      bodyContent.scrollTop = document
        .getElementById("leftPanel")
        .getBoundingClientRect().height;
      singlePost.scrollTop = 0;
    }
  }, [postID]);

  useEffect(() => {
    setPostID(-1);
    setPreviewScrollPosition(0);
    const rightPanel = document.getElementById("rightPanel");
    if (rightPanel) {
      rightPanel.scrollTop = 0;
      handleLocation(null);
    }
    const position = document
      .getElementById("leftPanel")
      .getBoundingClientRect().height;
    setBodyContentScrollPosition(position);
    const bodyContent = document.getElementById("bodyContent");
    if (bodyContent) {
      bodyContent.scrollTop = position;
      handleLocation(null);
    }
  }, [cities]);

  const previewPostScroll = (e) => {
    if (postID == -1) {
      setPreviewScrollPosition(e.target.scrollTop);
    }
  };

  const handleBodyContentScroll = (e) => {
    if (postID == -1) {
      setBodyContentScrollPosition(e.target.scrollTop);
    }
  };

  const handleReadMore = (postId) => {
    const user = firebase.auth().currentUser;
    const viewCount =
      parseInt(localStorage.getItem("view_post_count"), 10) || 0;
    window.history.replaceState(null, null, `/stories/${getUniqueUrl(postId)}`);
    setPostID(postId);
    if (user || viewCount < 2) {
      localStorage.setItem("view_post_count", viewCount + 1);
    } else {
      MicroModal.show("joinFirstSurveyModal");
    }
  };

  // Generate post previews given a list of post data
  const generatePosts = async (toParse) => {
    let entries = toParse.map((post, index) => {
      // Determine the "preview cap" that limits the experience text
      let wordCap = PREVIEW_WORD_LIMIT;
      let words = post.experience.split(" ");
      if (words.length < wordCap) {
        wordCap = post.experience.length;
      }
      let characterCount = 0;
      for (let i = 0; i < wordCap; i++) {
        if (words[i]) {
          characterCount += words[i].length;
        }
      }

      // Turn the map entries into an array
      // If the key === post.id, add those images into preview post
      let postSpecificImages = postImages ? postImages.get(parseInt(post.PostID)) : null;

      return (
        <PreviewPost
          key={parseInt(post.PostID)}
          keyCount={parseInt(post.PostID)}
          wordCap={wordCap}
          characterCount={characterCount}
          handleReadMore={(postId) => handleReadMore(postId)}
          post={post}
          postImages={postSpecificImages ? postSpecificImages : []}
          posterImages={posterImages}
        />
      );
    });

    setPreviewPosts(entries);
  };

  // Handle location and filter update for polygons and posts
  let handleLocation = (e) => {
    if (e !== null && e !== undefined) {
      e.preventDefault();
    }

    // Search raw data for post results including filters
    const filteredPosts = posts.filter((currentPost) => {
      if (
        cities.length == 0 ||
        (cities.length > 0 && cities.indexOf(currentPost.city) > -1)
      ) {
        if (
          homeType === -1 ||
          homeType === "Any type" ||
          currentPost.type === homeType
        ) {
          if (
            dateSold === -1 ||
            dateSold === "All time" ||
            checkDate(currentPost.date, dateSold)
          ) {
            if (
              currentPost.sold === "-1" ||
              (parseInt(currentPost.sold) >= lowPrice &&
                parseInt(currentPost.sold) <= highPrice)
            ) {
              return currentPost;
            }
          }
        }
      }
    });
    generatePosts(filteredPosts);
  };

  // Create an object full of all of the single posts to render (preview versions of them)
  const finalPostSet = (
    <div>
      <div id="locInfo">
        <p id="previewTitle">Home Buying Stories</p>
        {previewPosts}
      </div>
    </div>
  );

  // Primary app content
  return (
    <div id="rootPage">
      <div id="topPortion">
        <TopNav user={user} setUser={setUser} />
        {posts.length > 0 && (
          <FilterBar
            updateType={setHomeType}
            updateDate={setDateSold}
            updateLowPrice={setLowPrice}
            updateHighPrice={setHighPrice}
            handleLocation={handleLocation}
            homeType={homeType}
            dateSold={dateSold}
            lowPrice={lowPrice}
            highPrice={highPrice}
          />
        )}
      </div>
      <div id="bodyContent" onScroll={handleBodyContentScroll}>
        <div className="row" id="panelContainer">
          <div className="col-lg-7" id="leftPanel">
            {posts.length > 0 && (
              <GoogleMap
                onMarkerSelected={(marker) =>
                  setCities(marker ? marker.cities : [])
                }
              />
            )}
          </div>
          {postID != -1 ? (
            <div className="col-lg-5" id="singlePost">
              <SinglePost
                post={posts.find((post) => post.PostID == postID)}
                onClose={() => {
                  setPostID(-1);
                  window.history.replaceState(null, null, "/stories");
                }}
                agentImages={agentImages}
                postImages={postImages}
                posterImages={posterImages}
                user={user}
              />
            </div>
          ) : (
            <div
              className="col-lg-5"
              id="rightPanel"
              onScroll={previewPostScroll}
            >
              {finalPostSet}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
