// Helper function to filter the correct date of post
const checkDate = (date, dateSold) => {
  let dateSplit = date.split(' ');
  let month = 'January';
  let year = dateSplit[0];
  if (dateSplit.length == 2) {
    month = dateSplit[0];
    year = dateSplit[1];
  }
  const postDate = new Date(`${month} 1, ${year}`).getTime();
  const currDate = new Date().getTime();
  let timeDiff = 0;
  switch (dateSold) {
    case '6 months':
      timeDiff = 15552000000;
      break;
    case '12 months':
      timeDiff = 31104000000;
      break;
    case '3 years':
      timeDiff = 93311999999;
      break;
    default:
      timeDiff = 'default';
  }
  if (currDate - postDate <= timeDiff) {
    return true;
  }
  return false;
};

export default checkDate;
