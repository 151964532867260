import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Navbar } from "reactstrap";
import TypeSelectDrop from "./TypeSelectDrop.jsx";
import SoldSelectDrop from "./SoldSelectDrop.jsx";
import PriceSelectDrop from "./PriceSelectDrop.jsx";
import SearchIcon from "../../img/zoom@3x.png";
import clearIcon from "../../img/i-remove@3x.png";
import "./filterBar.css";

const MAXIMUM_RANGE = 2000000;

const FilterBar = (props) => {
  const { homeType, dateSold, lowPrice, highPrice } = props;
  const ranges = ["6 months", "12 months", "3 years", "All time"];
  const types = ["Any type", "House", "Townhouse", "Condo"];
  const [showFilters, setShowFilters] = useState(false);

  const [range, setRange] = useState("All time");
  const [type, setType] = useState("Any type");
  const [renderedRange, setRenderedRange] = useState("All time");
  const [renderedType, setRenderedType] = useState("Type");
  const [low, setLow] = useState(0);
  const [high, setHigh] = useState(MAXIMUM_RANGE);

  // Update search on any filter change
  useEffect(() => {
    props.handleLocation();
  }, [homeType, dateSold, lowPrice, highPrice]);

  let handleClear = (e) => {
    e.preventDefault();
    props.updateType("Any type");
    props.updateDate("All time");
    setRange("All time");
    setType("Any type");
    setRenderedRange("All time");
    setRenderedType("Type");
    setLow(0);
    setHigh(MAXIMUM_RANGE);
    props.updateLowPrice(0);
    props.updateHighPrice(MAXIMUM_RANGE);
  };

  let handleType = (e) => {
    setType(e.target.id);
    setRenderedType(e.target.id);
    props.updateType(e.target.id);
  };

  let handleRange = (range) => {
    setRange(range);
    setRenderedRange(range);
    props.updateDate(range);
  };

  let handlePriceLow = (price) => {
    setLow(price);
    props.updateLowPrice(price);
  };

  let handlePriceHigh = (price) => {
    setHigh(price);
    props.updateHighPrice(price);
  };

  const handleClick = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <div expand="md" id="filterBar" className="ml-auto">
      <div id="filterCircle" className="d-xl-none">
        <img
          src="img/filter.png"
          srcSet="img/filter@2x.png 2x,
      img/filter@3x.png 3x"
          className="filter"
          onClick={handleClick}
        ></img>
      </div>
      <div className="d-none d-xl-flex">
        <TypeSelectDrop
          handleType={handleType}
          type={type}
          types={types}
          renderedType={renderedType}
        />
        <SoldSelectDrop
          handleRange={handleRange}
          range={range}
          ranges={ranges}
          renderedRange={renderedRange}
        />
        <PriceSelectDrop
          handlePriceLow={handlePriceLow}
          handlePriceHigh={handlePriceHigh}
          low={low}
          high={high}
          updateSearch={props.handleLocation}
        />
        <div id="clearButtonBox" onClick={handleClear}>
          <div className="inline1">
            <img id="clearFilterX" src={clearIcon}></img>
          </div>
          <div className="inline2">
            <p id="cleartext">Clear all filters</p>
          </div>
        </div>
      </div>
      {showFilters ? (
        <div
          id="mobileFilters"
          style={{ display: showFilters ? "block" : "none" }}
        >
          <p id="FilterBy">Filter by</p>
          <TypeSelectDrop
            handleType={handleType}
            type={type}
            types={types}
            renderedType={renderedType}
          />
          <SoldSelectDrop
            handleRange={handleRange}
            range={range}
            ranges={ranges}
            renderedRange={renderedRange}
          />
          <PriceSelectDrop
            handlePriceLow={handlePriceLow}
            handlePriceHigh={handlePriceHigh}
            low={low}
            high={high}
            updateSearch={props.handleLocation}
          />
          <div id="clearButtonBox" onClick={handleClear}>
            <div id="clearPos">
              <div className="inline1">
                <img id="clearFilterX" src={clearIcon}></img>
              </div>
              <div className="inline2">
                <p id="cleartext">Reset filter</p>
              </div>
            </div>
            <div className="mobileSave" onClick={handleClick}>
              <p>Save</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterBar;
