import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Comment } from 'semantic-ui-react';
import axios from 'axios';
import Home from '../../img/home@3x.png';
import Check from '../../img/check-single@3x.png';
import Clock from '../../img/clock@3x.png';
import Remove from '../../img/i-remove-white@3x.png';
import Pin from '../../img/pin-3@3x.png';
import Logo from '../../img/logo-notext@3x.png';
import './right-panel.css';
import ImageModal from './ImageModal.jsx';
import 'firebase/auth';
import CommentForm from '../Comments/CommentForm.jsx';
import { FB_FUNCTION_URL } from '../const';

const SinglePost = ({ post, onClose, agentImages, postImages, posterImages, user }) => {
  const { title, neighborhood, city, type, date, experience,
    rating, list, offer, sold, agent, loan, percent, earnest, escalate, company, id, PostID } = post;
  const circumstance1 = post['circumstance 1'];
  const circumstance2 = post['circumstance 2'];
  const circumstance3 = post['circumstance 3'];
  const circumstance4 = post['circumstance 4'];
  const formatter = Intl.NumberFormat();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const filters = {
      postId: PostID,
    };
    axios.get(`${FB_FUNCTION_URL}/comments`, { params: filters })
      .then((res) => setComments(res.data));
  }, [0]);

  // Functions return true if there is enough datta to render the current div
  const renderDivTwo = () => postImages && postImages.has(parseInt(PostID, 10));
  const renderDivFour = () => rating !== '-1';
  const renderDivFive = () => loan !== '-1' || percent !== '-1' || earnest !== '-1' || escalate !== '-1'
    || list !== '-1' || offer !== '-1' || sold !== '-1';
  const renderDivSix = () => circumstance1 !== '-1';
  const renderDivSeven = () => agent !== '-1' || company !== '-1';
  // Take the "experience" and "rating" sections and manually create <p> elements whenevever
  // there is a line break
  const formatParagraphs = (paragraph) => {
    const paragraphs = paragraph.split('\r\n\r\n');
    const formatted = paragraphs.map((curr, index) => <p key={index}>{curr}</p>);
    return <>{formatted}</>;
  };

  // This handles the rendering for the "Terms" and "Other Terms" of the Post as seen in the parameter
  // Returns a formatted value of the terms's description if it is included in the post
  const getTerms = (termType) => {
    const terms = [];
    const types = [loan, percent, earnest, escalate];
    const types2 = [circumstance1, circumstance2, circumstance3, circumstance4];
    if (termType === 'termsRegular') {
      types.forEach((type) => {
        if (type !== '-1') {
          terms.push(
            <div className="term-row" key={type}>
              <img src={Check} alt="Check" className="check" />
              <p>{type}</p>
            </div>,
          );
        }
      });
    } else if (termType === 'termsOther') {
      types2.forEach((type) => {
        if (type !== '-1') {
          terms.push(
            <div className="term-row" key={type}>
              <img src={Check} alt="Check" className="check" />
              <p>{type}</p>
            </div>,
          );
        }
      });
    }
    return terms.length > 0
      ? (
        <div>
          {termType === 'termsRegular'
            ? <h2>Terms</h2>
            : <h2>Other Terms</h2>}
          {terms}
        </div>
      )
      : null;
  };

  // Returns a rendered view for list, offer, and sold prices
  const getPrices = () => {
    const descs = [];
    const types = [list, offer, sold];
    const typeNames = ['List', 'Offer', 'Sold'];
    types.forEach((type, index) => {
      if (type !== '-1') {
        descs.push(
          <div className="term-row" key={index}>
            <p>
              {typeNames[index]}
              : &nbsp;
            </p>
            <p className="price-desc">
              $
              {formatter.format(type)}
            </p>
          </div>,
        );
      }
    });
    return (
      <>
        <h2>Prices</h2>
        {descs}
      </>
    );
  };

  // Get agent image from Firebase Storage or use default
  const getAgentImage = () => {
    const firstName = agent.split(' ')[0].toLowerCase();
    const lastName = agent.split(' ')[1].toLowerCase();
    let image = Logo;
    for (let i = 0; i < agentImages.length; i += 1) {
      const curr = agentImages[i].toLowerCase();
      if (curr.includes(firstName) && curr.includes(lastName)) {
        image = agentImages[i];
        break;
      }
    }

    return <img src={image} alt="agent" className="agent-image" />;
  };

  const getPosterImage = () => {
    if (post['poster name'] != -1) {
      return posterImages.find((posterImage) => posterImage.includes(post.PostID) && posterImage.includes(post['poster name']));
    }
    return null;
  };

  // TODO: this needs to be abstracted out into it's own class.
  const renderComments = () => (
    <Comment.Group threaded>
      <h3 className="ui center aligned header">
        Comments
      </h3>
      {comments
        .map((comment, index) => (
          <Comment key={index}>
            <Comment.Content>
              <Comment.Author as="a">
                {
                comment.firstName
                  ? `${comment.firstName ? comment.firstName : ''} ${comment.lastName ? `${comment.lastName[0]}.` : ''}`
                  : comment.author
                }
              </Comment.Author>
              <Comment.Text style={{ color: 'grey' }}>{comment.text}</Comment.Text>
            </Comment.Content>
          </Comment>
        ))}
    </Comment.Group>
  );

  // append new user comment to comments
  const addComment = (ee) => setComments(() => [...comments, ee]);

  return (
    <div className="single-post-container">

      {/* First Div */}
      <div className="first-div">
        <Row className="first-div-first-row">
          <Col xs={12} sm={9}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                {post['poster name'] != -1 && (
                  <p className="poster-name" style={{ marginBottom: getPosterImage() ? '0' : '1rem' }}>{post['poster name']}</p>
                )}
                {getPosterImage() && (
                  <img src={getPosterImage()} alt="poster" className="poster-image" />
                )}
              </div>
              <h1 style={{ marginLeft: (getPosterImage() || post['poster name'] != -1) ? 10 : 0, marginBottom: 10 }}>{title}</h1>
            </div>
          </Col>
          <Col xs={3}>
            <button className="close-button" onClick={onClose}>
              <img src={Remove} alt="Remove" className="icon remove" />
              Close
            </button>
          </Col>
        </Row>

        <div className="first-div-second-row">
          <div><img src={Pin} alt="Pin" className="icon pin" /><p>{neighborhood == -1 ? "" : `${neighborhood}, `}{city}</p></div>
          <div><img src={Home} alt="Home" className="icon home" /><p>{type}</p></div>
          <div><img src={Clock} alt="Clock" className="icon clock" /><p>Sold {date}</p></div>
        </div>
      </div>

      {/* Second Div: Images */}
      {renderDivTwo()
        && (
        <div className="div-container">
          <ImageModal images={postImages.get(parseInt(PostID, 10))} imageSize="carousel-image-small" id={PostID} />
        </div>
        )}

      {/* Third Div */}
      <div className="div-container">
        <h2>My Story</h2>
        {formatParagraphs(experience)}
        <hr />
      </div>

      {/* Fourth Div */}
      {renderDivFour()
      && (
      <div className="div-container">
        <h2>The Neighborhood</h2>
        {formatParagraphs(rating)}
        <hr />
      </div>
      )}

      {/* Fifth Div: Terms */}
      {renderDivFive()
        && (
        <div className="div-container">
          <div className="fifth-div-first-row">
            {getTerms('termsRegular') && (
            <div className="terms-col">{getTerms('termsRegular')}</div>
            )}
            { list !== '-1' && offer !== '-1' && sold !== '-1' && <div>{getPrices()}</div>}
          </div>
          <hr />
        </div>
        )}

      {/* Sixth Div */}
      {renderDivSix()
        && (
        <div className="div-container">
          {getTerms('termsOther')}
          <hr />
        </div>
        )}

      {/* Seventh Div */}
      {renderDivSeven()
          && (
          <div className="div-container">
            <div className="agent-image-text">
              {agent !== '-1' && getAgentImage()}
              <div className="seventh-div-second-col">
                <h2>{agent !== '-1' ? agent : null}</h2>
                <p>{company !== '-1' ? company : null}</p>
              </div>
            </div>
            <hr />
          </div>
          )}

      <div className="div-container">
        {renderComments()}
      </div>

      {/* Last Div */}
      <div className="div-container">
        <CommentForm
          postId={PostID}
          user={user}
          commentCount={comments.length}
          addComment={addComment}
        />
      </div>
    </div>

  );
};

export default SinglePost;
