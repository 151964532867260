import React, { useState, useContext } from "react";
import MicroModal from "micromodal";
import { store } from "../Store/index.jsx";
import "./join_survey.scss";

const JoinFirstSurveyModal = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const globalState = useContext(store);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    globalState.dispatch({
      type: "firstSurvey",
      firstSurvey: e.target.value,
    });
    MicroModal.close("joinFirstSurveyModal");
    MicroModal.show("joinSecondSurveyModal");
    setSelectedOption(null);
  };

  return (
    <div
      className="modal micromodal-slide"
      id="joinFirstSurveyModal"
      aria-hidden="true"
    >
      <div className="modal__overlay" tabIndex="-1">
        <div
          className="modal__container modal__containerXS"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
        >
          <header className="modal__header">
            <h2 className="modal__title" id="modal-1-title">
              Where are you in your home buying journey?
            </h2>
          </header>
          <main className="modal__content" id="modal-1-content">
            <form className="ml-4">
              <label className="radio-container">
                I'm touring homes
                <input
                  type="radio"
                  value="touring_homes"
                  name="buying_journey"
                  checked={selectedOption === "touring_homes"}
                  onChange={handleOptionChange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="radio-container">
                I'm looking at homes online
                <input
                  type="radio"
                  value="looking_at_homes_online"
                  name="buying_journey"
                  checked={selectedOption === "looking_at_homes_online"}
                  onChange={handleOptionChange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="radio-container">
                I'm looking to buy in the next year
                <input
                  type="radio"
                  value="looking_to_buy_next_year"
                  name="buying_journey"
                  checked={selectedOption === "looking_to_buy_next_year"}
                  onChange={handleOptionChange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="radio-container">
                I'm beginning to consider buying
                <input
                  type="radio"
                  value="begin_consider_buying"
                  name="buying_journey"
                  checked={selectedOption === "begin_consider_buying"}
                  onChange={handleOptionChange}
                />
                <span className="checkmark"></span>
              </label>
            </form>
          </main>
          <p>
            <span id="alreadyMem">Already a Member? </span>
            <a
              id="redirectText"
              href="#"
              onClick={() => {
                MicroModal.close("joinFirstSurveyModal");
                MicroModal.show("signIn");
              }}
            >
              Sign In
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default JoinFirstSurveyModal;
