import React, { useState, useContext } from "react";
import MicroModal from "micromodal";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { store } from "../Store/index.jsx";
import CloseIcon from "../../img/close@3x.png";
import Google from "../../img/google@3x.png";
import Facebook from "../../img/facebook@3x.png";
import Unlocked from "../../img/padlock-unlocked@3x.png";

const SignInModal = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const globalState = useContext(store);

  const gprovider = new firebase.auth.GoogleAuthProvider();
  const fprovider = new firebase.auth.FacebookAuthProvider();

  const handleSignIn = (e) => {
    console.log(username);
    setError("");
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(function () {
        MicroModal.close("signIn");
      })
      .catch(function (error) {
        setError(error.message);
      });
  };

  const handleFacebookSignUp = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithPopup(fprovider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        saveUserInfo(
          user.displayName.split(" ").slice(0, -1).join(" "),
          user.displayName.split(" ").slice(-1).join(" "),
          user.photoURL,
          globalState.state.join ? globalState.state.join.firstSurvey : null,
          globalState.state.join ? globalState.state.join.secondSurvey : null
        );
        MicroModal.close("joinModal");
        MicroModal.close("signIn");
      })
      .catch(function (error) {
        setError(error.message);
      });
  };

  const handleGoogleSignUp = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithPopup(gprovider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        saveUserInfo(
          user.displayName.split(" ").slice(0, -1).join(" "),
          user.displayName.split(" ").slice(-1).join(" "),
          user.photoURL,
          globalState.state.join ? globalState.state.join.firstSurvey : null,
          globalState.state.join ? globalState.state.join.secondSurvey : null
        );
        MicroModal.close("joinModal");
        MicroModal.close("signIn");
      })
      .catch(function (error) {
        setError(error.message);
      });
  };

  const saveUserInfo = (
    firstName,
    lastName,
    photoURL,
    joinFirstSurvey,
    joinSecondSurvey
  ) => {
    const user = firebase.auth().currentUser;
    firebase.database().ref(`Users/${user.uid}`).set({
      firstName,
      lastName,
      photoURL,
      joinFirstSurvey,
      joinSecondSurvey,
    });
  };

  const isViewPostLimited = () => {
    const viewCount =
      parseInt(localStorage.getItem("view_post_count"), 10) || 0;
    return viewCount >= 4;
  };

  const modalContent = () => {
    return (
      <div
        className={
          error === ""
            ? "modal__container modal__containerMD"
            : "modal__container modal__containerLG"
        }
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-1-title"
      >
        <header className="modal__header">
          <h2 className="modal__title" id="modal-1-title">
            Sign In With...
          </h2>
        </header>
        <main className="modal__content" id="modal-1-content">
          <form>
            <div className="form-group">
              <input
                name="username"
                className="form-control nameSignInInput"
                placeholder="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control passwordSignInInput passwordBox"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error != "" && (
              <div className="form-group">
                <p className="errorMessage">{error}</p>
              </div>
            )}
            <div className="form-group">
              <img id="unlock" src={Unlocked} />
              <button
                type="submit"
                id="signInButton"
                className="btn btn-primary"
                onClick={(e) => handleSignIn(e)}
              >
                Sign In
              </button>
              <button
                id="passwordForget"
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  MicroModal.close("signIn");
                  MicroModal.show("forgotPassword");
                }}
              >
                Forget your password?
              </button>
            </div>
          </form>
        </main>
        <footer className="modal__footer">
          {!isViewPostLimited() && (
            <button
              className="closeButton"
              data-micromodal-close
              aria-label="Close this dialog window"
            >
              <img
                aria-hidden="true"
                id="closeIcon"
                src={CloseIcon}
                data-micromodal-close
              />
            </button>
          )}
          <p className="or-bar">
            <span>OR</span>
          </p>
          <div className="thirdPartyContainer">
            <img className="thirdPartyIcon" src={Facebook} />
            <button
              className="thirdPartyButton"
              onClick={(e) => handleFacebookSignUp(e)}
            >
              Facebook
            </button>
          </div>
          <div className="thirdPartyContainer">
            <img className="thirdPartyIcon" src={Google} />
            <button
              className="thirdPartyButton"
              onClick={(e) => handleGoogleSignUp(e)}
            >
              Google
            </button>
          </div>
        </footer>
      </div>
    );
  };

  return (
    <div className="modal micromodal-slide" id="signIn" aria-hidden="true">
      {isViewPostLimited() ? (
        <div className="modal__overlay" tabIndex="-1">
          {modalContent()}
        </div>
      ) : (
        <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
          {modalContent()}
        </div>
      )}
    </div>
  );
};

export default SignInModal;
