import React, { useState, useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Arrow from '../../img/arrow-white@3x.png';
import Img from '../../img/img@3x.png';
import './right-panel.css';


// React Responsive Carousel https://www.npmjs.com/package/react-responsive-carousel
const ImageCarousel = ({ startImage, images, imageSize }) => {
    const [currentImage, setCurrentImage] = useState(startImage);
    const [renderedImages, setRenderedImages] = useState([]);

    // Render all firebase images into img div components
    useEffect(() => {
        const rendered = [];
        for (let i = 0; i < images.length; i++) {
            rendered.push(makeImageComponent(images[i], i));
        }
        setRenderedImages(rendered);
    }, images)

    useEffect(() => {
        setCurrentImage(startImage);
    }, [startImage]);
    
    const makeImageComponent = (imageURL, index) => {
        return (
            <div key={index}>
                <img src={imageURL} alt="house" className={imageSize}/>
            </div>
        )
    }

    const updateCurrentSlide = (index) => {
        if (index < 0 || index > images.length - 1) return;
        if (currentImage !== index) {
            setCurrentImage(index);
        }
    };

    // Render the arrow on the button according to direction and number of images left in either direction
    const getArrow = (direction) => {
        if (direction === "left") {
            if (currentImage <= 0) {
                return <img src={Arrow} alt={direction} className="carousel-arrow arrow-left arrow-end" />
            }
            return <img src={Arrow} alt={direction} className="carousel-arrow arrow-left" />
        } else if (direction === "right") {
            if (currentImage >= images.length - 1) {
                return <img src={Arrow} alt={direction} className="carousel-arrow arrow-end" />
            } else {
                return <img src={Arrow} alt={direction} className="carousel-arrow" />
            }
        }
    }

    return (
        <div className={imageSize == "carousel-image-small" ? "carousel-container-small" : "carousel-container-large"}>
            <Carousel
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                autoPlay={false}
                selectedItem={currentImage}
                onChange={updateCurrentSlide}
                dynamicHeight={true}
                className={imageSize == "carousel-image-small" && "carousel-small"}
            >{renderedImages}
            </Carousel>
            {images.length > 1 &&
                <div className={imageSize == "carousel-image-small" ? `carousel-components` : `carousel-components-large`}>
                    <div className="carousel-num-images">
                        <img src={Img} alt="image icon" />
                        <p>{images.length}</p>
                    </div>
                    <div className="carousel-button-container">
                        <button className="carousel-button" onClick={() => updateCurrentSlide(currentImage - 1)}>{getArrow("left")}</button>
                        <div className="carousel-button-line"></div>
                        <button className="carousel-button" onClick={() => updateCurrentSlide(currentImage + 1)}>{getArrow("right")}</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default ImageCarousel;