import React, { useState } from 'react';
import {
  Form, TextArea, Message, Button,
} from 'semantic-ui-react';
import axios from 'axios';
import MicroModal from 'micromodal'; // es6 module
import { FB_FUNCTION_URL } from '../const';

const CommentForm = ({
  postId, user, commentCount, addComment,
}) => {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event) => {
    if (value === '') {
      setErrorMessage('Please leave a comment.');
      setValue('');
      return;
    }

    if (user.length === 0) {
      MicroModal.show('joinModal');
      return;
    }

    // Formatting for comment post; if error -> set empty strings and show error msg to user
    const nameArr = user.name.split(' ') ? user.name.split(' ') : user.name;
    const authorFirstName = nameArr[0] ? nameArr[0] : '';
    const authorLastName = nameArr[1] ? nameArr[1] : '';
    const authorLastInitial = authorLastName[0] ? ` ${authorLastName[0]}.` : '';
    const author = authorFirstName + authorLastInitial;

    if (user.name === undefined || user.name.length < 3) {
      setErrorMessage('Invalid user name.');
      setValue('');
      return;
    }

    event.preventDefault(); // don't actually submit the form and refresh the page.

    setLoading(true);
    setErrorMessage('');

    try {
      // make the call to firebase to update this postId
      const comment = {
        commentId: `${postId}/${commentCount}`,
        firstName: authorFirstName,
        lastName: authorLastName,
        text: value,
      };
      const displayComment = {
        author, text: value,
      };
      await axios.put(`${FB_FUNCTION_URL}/addComment`, comment);
      addComment(displayComment); // display new comment if db write successful
    } catch (err) {
      setErrorMessage(err.message);
    }

    setLoading(false);
    setValue('');
  };

  return (
    <Form onSubmit={(e) => onSubmit(e)} error={!!errorMessage}>
      <Form.Field>
        <Message error header="Oops!" content={errorMessage} />
        <TextArea
          placeholder="Comment"
          value={value}
          style={{ color: 'grey', margin: '7px 0' }}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          style={{
            backgroundColor: '#6665FF',
            color: 'white',
            borderRadius: '19px',
          }}
          loading={loading}
          floated="right"
        >
          Comment
        </Button>
      </Form.Field>
    </Form>
  );
};

export default CommentForm;
