import React, { useState, useEffect, useRef } from "react";
import MicroModal from "micromodal";
import * as firebase from "firebase/app";
import "firebase/auth";
import CloseIcon from "../../img/close@3x.png";

const ForgotPasswordModal = () => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");

  const handleForgotPassword = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .sendPasswordResetEmail(username)
      .then(function () {
        MicroModal.close('forgotPassword');
        MicroModal.open('signIn');
      })
      .catch(function (error) {
        setError(error.message);
      });
  };

  return (
    <div
      className="modal micromodal-slide"
      id="forgotPassword"
      aria-hidden="true"
    >
      <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
        <div
          className="modal__container modal__containerSM"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
        >
          <header className="modal__header">
            <h2 className="modal__title" id="modal-1-title">
              Forgot Password?
            </h2>
          </header>
          <main className="modal__content" id="modal-1-content">
            <p>
              No worries! We'll send you an email to help you reset your
              password
            </p>
            <form>
              <div className="thirdPartyContainer">
                <input
                  name="username"
                  className="form-control nameSignInInput forgotInput"
                  placeholder="Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              {error != "" && (
                <div className="form-group">
                  <p className="errorMessage">{error}</p>
                </div>
              )}
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary signIn"
                  onClick={(e) => handleForgotPassword(e)}
                >
                  Send Email
                </button>
              </div>
            </form>
          </main>
          <footer className="modal__footer">
            <button
              className="closeButton"
              data-micromodal-close
              aria-label="Close this dialog window"
            >
              <img
                aria-hidden="true"
                id="closeIcon"
                src={CloseIcon}
                data-micromodal-close
              />
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
