import React, { useState, useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Arrow from '../../img/arrow-white@3x.png';
import Img from '../../img/img@3x.png';
import styles from './right-panel.css';
import CloseIcon from "../../img/close@3x.png";
import "../TopNavigation/micromodal.css";
import { Modal } from "react-bootstrap";
import ImageCarousel from "./ImageCarousel.jsx";
import MicroModal from "micromodal"; // es6 module

// React Responsive Carousel https://www.npmjs.com/package/react-responsive-carousel
const ImageModal = ({ images, imageSize, id }) => {
    const [currentImage, setCurrentImage] = useState(() => 0);
    const [renderedImages, setRenderedImages] = useState([]);
    const [renderedImagesXL, setRenderedImagesXL] = useState([]);
    const [startImage, setStartImage] = useState(()=>0);
    const [showModal, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Render all firebase images into img div components
    useEffect(() => {
        const rendered = [];
        const renderedXL = [];
        for (let i = 0; i < images.length; i++) {
            rendered.push(makeImageComponent(images[i], i, imageSize));
            renderedXL.push(makeImageComponent(images[i], i, "carousel-image-large"));
        }
        setRenderedImages(rendered);
        setRenderedImagesXL(renderedXL);
    }, images)

    const makeImageComponent = (imageURL, index, size) => {
        return (
            <div key={index} onClick={() => renderMicromodal(index)}>
                <img src={imageURL} alt="house" className={size}/>
            </div>
        )
    }

    const renderMicromodal = (imagePassed) => {
        // trying to set currentImage to the page that should be shown in modal 
        setCurrentImage(imagePassed);
        MicroModal.show(`${id}`);
      };

    const updateCurrentSlide = (index) => {
        if (index < 0 || index > images.length - 1) return;
        if (currentImage !== index) {
            setCurrentImage(index);
        }
    };

    // Render the arrow on the button according to direction and number of images left in either direction
    const getArrow = (direction) => {
        if (direction === "left") {
            if (currentImage <= 0) {
                return <img src={Arrow} alt={direction} className="carousel-arrow arrow-left arrow-end" />
            }
            return <img src={Arrow} alt={direction} className="carousel-arrow arrow-left" />
        } else if (direction === "right") {
            if (currentImage >= images.length - 1) {
                return <img src={Arrow} alt={direction} className="carousel-arrow arrow-end" />
            } else {
                return <img src={Arrow} alt={direction} className="carousel-arrow" />
            }
        }
    }

    return (
        <div style={{ cursor: 'pointer' }}>
            <Carousel
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                autoPlay={false}
                selectedItem={currentImage}
                onChange={updateCurrentSlide}
                dynamicHeight={true}
                className={imageSize == "carousel-image-small" && "carousel-small"}
                interval={500}
            >{renderedImages}
            </Carousel>
            {images.length > 1 &&
                <div className={imageSize == "carousel-image-small" ? `carousel-components` : `carousel-components-large`}>
                    <div className="carousel-num-images">
                        <img src={Img} alt="image icon" />
                        <p>{images.length}</p>
                    </div>
                    <div className="carousel-button-container">
                        <button className="carousel-button" onClick={() => updateCurrentSlide(currentImage - 1)}>{getArrow("left")}</button>
                        <div className="carousel-button-line"></div>
                        <button className="carousel-button" onClick={() => updateCurrentSlide(currentImage + 1)}>{getArrow("right")}</button>
                    </div>
                </div>
            }
            {/* Modal*/}
            <div className="modal micromodal-slide" id={id} aria-hidden="true">
                <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
                    <div
                    className={"modal__container modal__containerPREVIEW"}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-1-title"
                    >
                    <main id="modal-1-content">
                    <div className="carousel-container-large">
                    <Carousel
                        showArrows={false}
                        showStatus={false}
                        showIndicators={false}
                        showThumbs={false}
                        autoPlay={false}
                        selectedItem={currentImage}
                        onChange={updateCurrentSlide}
                        dynamicHeight={true}
                        className={imageSize == "carousel-image-large" && "carousel-large"}
                    >{renderedImagesXL}
                    </Carousel>
                    {images.length > 1 &&
                        <div className={imageSize !== "carousel-image-small" ? `carousel-components` : `carousel-components-large`}>
                            <div className="carousel-num-images">
                                <img src={Img} alt="image icon" />
                                <p>{images.length}</p>
                            </div>
                            <div className="carousel-button-container">
                                <button className="carousel-button" onClick={() => updateCurrentSlide(currentImage - 1)}>{getArrow("left")}</button>
                                <div className="carousel-button-line"></div>
                                <button className="carousel-button" onClick={() => updateCurrentSlide(currentImage + 1)}>{getArrow("right")}</button>
                            </div>
                        </div>
                    }
                </div>
                    </main>
                    <footer className="modal__footer">
                        <button
                        className="closeButton"
                        data-micromodal-close
                        aria-label="Close this dialog window"
                        >
                        <img
                            aria-hidden="true"
                            id="closeIcon"
                            src={CloseIcon}
                            data-micromodal-close
                        />
                        </button>
                    </footer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageModal;