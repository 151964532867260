import React, { useState } from 'react';
import Camera from '../../img/camera-3@3x.png';
import MicroModal from "micromodal"; // es6 module
import ImageCarousel from './ImageCarousel.jsx';
import CloseIcon from "../../img/close@3x.png";
import "../TopNavigation/micromodal.css";
import './right-panel.css';

const PreviewImageModal = ({ postImages, id }) => {
    const [startIndex, setStartIndex] = useState(0);

    const renderMicromodal = () => {
        MicroModal.show(`${id}`);
    }

    const renderThirdDiv = () => {
        return (
            <div className="pim-third-div">
                <button onClick={() => {setStartIndex(2); renderMicromodal()}} className="pim-button">
                    <div className="pim-button-mask">
                        <div className="pim-cam-text">
                            <img src={Camera} alt="more images" className="pim-camera" />
                            <p className="pim-text">+{postImages.length}</p>
                        </div>
                    </div>
                        {postImages.length > 2
                            ? <img src={postImages[2]} alt="house" className="pim-img-3" />
                            : <img src={postImages[0]} alt="house" className="pim-img-3" />
                        }
                </button>
            </div>
        )
    }

    const renderModal = () => {
        return (
            <div className="modal micromodal-slide" id={id} aria-hidden="true">
                <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
                    <div
                        className={"modal__container modal__containerPREVIEW"}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-1-title"
                    >
                        <main id="modal-1-content">
                            <ImageCarousel startImage={startIndex} images={postImages} imageSize="carousel-image-large" />
                        </main>
                        <footer className="modal__footer">
                            <button
                                className="closeButton"
                                data-micromodal-close
                                aria-label="Close this dialog window"
                            >
                                <img
                                    aria-hidden="true"
                                    id="closeIcon"
                                    src={CloseIcon}
                                    data-micromodal-close
                                />
                            </button>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }

    if (postImages.length === 0) {
        return (
            <div></div>
        );
    } else if (postImages.length == 1) {
        return (
            <div className="pim-container">
                <img src={postImages[0]} alt="house" className="pim-img-single" onClick={() => {setStartIndex(0); renderMicromodal()}} />
                {renderModal()}
            </div>
        );
    } else {
        return (
            <div className={`pim-container pim-contain-multiple`}>
                <div className="pim-img-1-container" onClick={() => {setStartIndex(0); renderMicromodal()}}>
                    <img src={postImages[0]} alt="house" className="pim-img-1" />
                </div>
                <div className="pim-col">
                    <div className="pim-img-2-container" onClick={() => {setStartIndex(1); renderMicromodal()}}>
                        <img src={postImages[1]} alt="house" className="pim-img-2" />
                    </div>
                    {renderThirdDiv()}
                </div>
                {renderModal()}
            </div>
        );
    }
    
}

export default PreviewImageModal;
