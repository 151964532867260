import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import combineReducers from 'react-combine-reducers';
import joinReducer from './JoinReducer.jsx';

const initialState = {};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [rootReducer, initialRoot] = combineReducers({
    join: [joinReducer, undefined],
  });

  const [state, dispatch] = useReducer(rootReducer, initialRoot);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.node,
};

export { store, StateProvider };