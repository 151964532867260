import React, { useState } from 'react';
import { Button } from 'reactstrap';
import * as firebase from 'firebase/app';
// import $ from 'jquery';
import 'firebase/auth';
import MicroModal from 'micromodal'; // es6 module
import PadLock from '../../img/padlock-unlocked@3x.png';
import Plus from '../../img/e-add@3x.png';
import User from '../../img/user@3x.png';
// import Key from '../../img/password@3x.png';
// import Facebook from '../../img/facebook@3x.png';
import Logo from '../../img/logo@3xdark.png';
//import Bars from "../../img/icons8-menu-30.png";
import './nav.scss';
import './micromodal.css';
import { Link } from 'react-router-dom';
import JoinModal from '../Modal/JoinModal.jsx';
import SignInModal from '../Modal/SignInModal.jsx';
import ForgotPasswordModal from '../Modal/ForgotPasswordModal.jsx';
import JoinFirstSurveyModal from '../Modal/JoinFirstSurveyModal.jsx';
import JoinSecondSurveyModal from '../Modal/JoinSecondSurveyModal.jsx';

const topNav = ({ user, setUser }) => {
  // const fbProvider = new firebase.auth.FacebookAuthProvider();
  const [toggleState, setToggleState] = useState(false);

  const handleSignOut = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signOut()
      .then(
        function () {
          setUser([]);
        },
        function (error) {
          console.error('Sign Out Error', error);
        }
      );
  };

  const handleToggler = () => {
    setToggleState(!toggleState);
  };

  const renderNavUser = () => {
    return (
      <>
        <li className='nav-story-item'>
          <a href='#' className='customLink nav-link' id='signIn'>
            <img id='user' src={User} alt='user' />
            {user.name ? user.name : user.email}
          </a>
        </li>
        <Button onClick={(e) => handleSignOut(e)} id='joinButton'>
          Sign out
        </Button>
      </>
    );
  };
  const renderNavNoUser = () => {
    return (
      <>
        <li className='nav-story-item'>
          <a
            href='#'
            onClick={() => {
              MicroModal.show('signIn');
            }}
            className='customLink nav-link'
            id='signIn'
          >
            <img id='padlock' src={PadLock} alt='padlock' />
            Sign in
          </a>
        </li>
        <Button
          // className='nav-story-item'
          onClick={() => {
            MicroModal.show('joinFirstSurveyModal');
          }}
          id='joinButton'
        >
          Join
        </Button>
      </>
    );
  };

  return (
    <div>
      <JoinModal />
      <SignInModal />
      <ForgotPasswordModal />
      <JoinFirstSurveyModal />
      <JoinSecondSurveyModal />

      <header className='stories-header'>
        <nav className='navbar navbar-expand-lg navbar-custom'>
          {/* <div className='container'> */}
          <a className='navbar-brand d-flex w-50 m-0 p-0' href='/'>
            <div id='mainLogo'>
              <img src={Logo} alt='Villavox' />
            </div>
          </a>
          <button
            className='navbar-toggler p-0 border-0'
            type='button'
            data-toggle='offcanvas'
            onClick={handleToggler}
          >
            <span
              className={`navbar-toggler-icon  ${toggleState ? `open` : ''}`}
            />
          </button>

          <div
            className={`navbar-collapse offcanvas-collapse ${
              toggleState ? `open` : ''
            }`}
            id='navbarsDefault'
          >
            <ul className='navbar-nav ml-lg-auto mx-auto mr-lg-0 mt-0'>
              <li className='nav-story-item'>
                <Link to="/share" id='share' className='customLink nav-link' target="_blank">
                  <img id='add' src={Plus} alt='logo' />
                  Share your story
                </Link>
              </li>
              <div id='lineGap'></div>
              {user.length == 0 ? renderNavNoUser() : renderNavUser()}
            </ul>
          </div>
          {/* </div> */}
        </nav>
      </header>
    </div>
  );
};

export default topNav;
