import React from 'react';
import "./TermsOfUse.css";

const TermsOfUse = () => {
    return (
        <div className="WordSection1 container">
            <a className="navbar-brand mt-3 mb-5" href="/">
                <img src="../img/logo-black.png" alt="Villavox"
                    className=""/>
            </a>
            <h1 className="mb-3" className="spanStyleOne">Terms of Use</h1>

        <p className="MsoNormal" className="spanStyleTwo"><span className="spanStyleFive">1.
        Introduction </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >These Website
        Standard Terms And Conditions (these “Terms” or these “Website Standard Terms
        And Conditions”) contained herein on this webpage, shall govern your use of
        this website, including all pages within this website (collectively referred to
        herein below as this “Website”). These Terms apply in full force and effect to
        your use of this Website and by using this Website, you expressly accept all
        terms and conditions contained herein in full. You must not use this Website,
        if you have any objection to any of these Website Standard Terms And
        Conditions. </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >This Website is
        not for use by any minors (defined as those who are not at least 18 years of
        age), and you must not use this Website if you a minor. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">2.
        Intellectual Property Rights </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >Other than
        content you own, which you may have opted to include on this Website, under
        these Terms, VillaVox and/or its licensors own all rights to the intellectual
        property and material contained in this Website, and all such rights are
        reserved. </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >You are granted
        a limited license only, subject to the restrictions provided in these Terms,
        for purposes of viewing the material contained on this Website</span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">3.
        Restrictions </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >You are
        expressly and emphatically restricted from all of the following: </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >1. publishing
        any Website material in any media;</span><span className="spanStyleSix"></span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >2. selling,
        sublicensing and/or otherwise commercializing any Website material; </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >3. publicly
        performing and/or showing any Website material;</span><span className="spanStyleSix"></span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >4. using this
        Website in any way that is, or may be, damaging to this Website; </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >5. using this
        Website in any way that impacts user access to this Website; </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >6. using this
        Website contrary to applicable laws and regulations, or in a way that causes,
        or may cause, harm to the Website, or to any person or business entity;</span><span
        className="spanStyleSix"></span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >7. engaging in
        any data mining, data harvesting, data extracting or any other similar activity
        in relation to this Website, or while using this Website; </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >8. using this
        Website to engage in any advertising or marketing; </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >Certain areas of
        this Website are restricted from access by you and VillaVox may further
        restrict access by you to any areas of this Website, at any time, in its sole
        and absolute discretion. Any user ID and password you may have for this Website
        are confidential and you must maintain confidentiality of such information. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">4.
        Your Content </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >In these Website
        Standard Terms And Conditions, “Your Content” shall mean any audio, video,
        text, images or other material you choose to display on this Website. With
        respect to Your Content, by displaying it, you grant VillaVox a non-exclusive,
        worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce,
        adapt, publish, translate and distribute it in any and all media. </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >Your Content
        must be your own and must not be infringing on any third party’s rights. VillaVox
        reserves the right to remove any of Your Content from this Website at any time,
        and for any reason, without notice. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">5.
        No warranties </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >This Website is
        provided “as is,” with all faults, and VillaVox makes no express or implied
        representations or warranties, of any kind related to this Website or the
        materials contained on this Website. Additionally, nothing contained on this
        Website shall be construed as providing consult or advice to you. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">6.
        Limitation of liability </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >In no event
        shall VillaVox, nor any of its officers, directors and employees, be liable to
        you for anything arising out of or in any way connected with your use of this
        Website, whether such liability is under contract, tort or otherwise, and VillaVox,
        including its officers, directors and employees shall not be liable for any
        indirect, consequential or special liability arising out of or in any way
        related to your use of this Website. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">7.
        Indemnification </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >You hereby
        indemnify to the fullest extent VillaVox from and against any and all
        liabilities, costs, demands, causes of action, damages and expenses (including
        reasonable attorney’s fees) arising out of or in any way related to your breach
        of any of the provisions of these Terms. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">8.
        Severability </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >If any provision
        of these Terms is found to be unenforceable or invalid under any applicable
        law, such unenforceability or invalidity shall not render these Terms
        unenforceable or invalid as a whole, and such provisions shall be deleted
        without affecting the remaining provisions herein. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">9.
        Variation of Terms </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >VillaVox is
        permitted to revise these Terms at any time as it sees fit, and by using this
        Website you are expected to review such Terms on a regular basis to ensure you
        understand all terms and conditions governing use of this Website. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">10.
        Assignment </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >VillaVox shall
        be permitted to assign, transfer, and subcontract its rights and/or obligations
        under these Terms without any notification or consent required. However, you
        shall not be permitted to assign, transfer, or subcontract any of your rights
        and/or obligations under these Terms. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">11.
        Entire Agreement </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >These Terms,
        including any legal notices and disclaimers contained on this Website,
        constitute the entire agreement between VillaVox and you in relation to your
        use of this Website, and supersede all prior agreements and understandings with
        respect to the same. </span></p>

        <p className="MsoNormal" className="spanStyleFour"><span className="spanStyleFive">12.
        Governing Law &amp; Jurisdiction </span></p>

        <p className="MsoNormal" className="spanStyleThree"><span >These Terms will
        be governed by and construed in accordance with the laws of the State of Washington,
        and you submit to the non-exclusive jurisdiction of the state and federal
        courts located in Washington.</span></p>

        <p className="MsoNormal"><span style={{fontFamily:"Arial ,sans-serif"}}>&nbsp;</span></p>

        </div>
    );
}

export default TermsOfUse;