// CSS Imports *include in entry file only
import '../scss/_gilroy.scss';
import '../scss/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from '../site-two/App.js';
import axios from 'axios';
import $ from 'jquery';

/* Boostrap 4 Full Bundles */
// import 'bootstrap/dist/js/bootstrap.bundle';
/* Includes popper.js */
// import 'bootstrap'; /* Does not include popper. js */

// Uncomment 'popper.js' in webpack.config.js

/* Boostrap 4 Individual Components (Optional) */
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip'; /* requires popper.js */
// import 'bootstrap/js/dist/util';

if (process.env.NODE_ENV === 'development') {
  require('../../public/index.html');
}

// React Entrance
ReactDOM.render(<App />, document.getElementById('root'));
