import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import axios from 'axios';
import $ from 'jquery';

import './Landing.css';

import { all_neighborhood_city_combinations } from '../const'

// Legacy jQuery
$(document).ready(function () {
    $('a[href^=\'#\']').on('click', function (e) {
      if (!$(this).data('slide-to') && !$(this).data('slide')) {
        e.preventDefault();
        if ($('.offcanvas-collapse').hasClass('open')) {
          toggleMenu();
        }
        $('html, body').animate({
          scrollTop: $($(this).attr('href')).offset().top
        }, 1000);
      }
    });
  
    if ($(window.location.hash).length > 1) {
      $('html, body').animate({
        scrollTop: $(window.location.hash).offset().top
      }, 1000);
    }
});
  
function toggleMenu() {
    $('.offcanvas-collapse').toggleClass('open');
    $('html, body').toggleClass('overflow-hidden');
    setTimeout(function () {
        $('.navbar-toggler-icon').toggleClass('open');
    }, 250);
}
  
$(function () {
    $('[data-toggle="offcanvas"]').on('click', function (e) {
        e.stopImmediatePropagation();
        toggleMenu();
    });
});
  
$('#secondGea').on('click', function (e) {
    $("#registered").replaceWith("<button type=" + "submit" + " id=" +"modalSubmit" + " class='btn btn-primary btn-md'" + ">Register</button>");
    $("#done").replaceWith("<button type=" + "submit" + " id=" +"modalSubmit" + " class='btn btn-primary btn-md'" + ">Register</button>");
    $("#error").replaceWith("<button type=" + "submit" + " id=" +"modalSubmit" + " class='btn btn-primary btn-md'" + ">Register</button>");
});

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
  
const url = 'https://us-central1-villavox-a5e76.cloudfunctions.net/addInterestEmail';
function handleSubmit(e) {
    let formInput = $("#emailInput").val();
    if (validateEmail(formInput)) {
        console.log("replacing node entity");
        $("#modalSubmit").replaceWith("<center " + "id='loading'>" + "Processing...</center>");
        let emailData = {
            email: formInput
        }
        axios.post(url, emailData)
        .then((res) => {
            if (res.data === "Already queued") {
                $("#loading").replaceWith("<center " + " id='registered'>" + "Already Registered!</center>");
            }
            $("#loading").replaceWith("<center " + " id='done'>" + "Registered!</center>");
        })
        .catch(() => {
            $("#loading").replaceWith("<center " + " id='error'>" + "Error</center>");
        })
    }
    e.preventDefault();
}

// Legacy jQuery End

let Landing = (props) => {
    let [value, changeValue] = useState("");

    const handleLocSubmit = (e) => {
        e.preventDefault;
        if (value && value.split(",").length >= 2) {
            props.changeLoc(value);
        }
        props.history.push('mainDashboard');
    }

    return (
        <div id="landingPage">
            <main role="main" className="overflow-hidden">
                <header className="b-header">
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <div className="container">
                            <a className="navbar-brand" href="/">
                                <img src="../../img/logo.png" alt="Villavox"
                                    srcSet="../../img/logo.png 1x, ../../img/logo@2x.png 2x, ../../img/logo@3x.png 3x"
                                    className=""/>
                            </a>
                            <button className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="navbar-collapse offcanvas-collapse" id="navbarsDefault">
                                <ul className="navbar-nav ml-lg-auto mx-auto mr-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link" id="adjust" href="#about">About us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#how">How it works</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://blog.villavox.com">Blog</a>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/share" className="nav-link" target="_blank">
                                            Share your story
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    <div className="container">
                        <div className="b-header__jumbotron">
                            <h1 className="b-header__title" id="getHomeBuying">Every house has a story</h1>
                            <div className="row" style={{margin: "0px"}}>
                                <div className="col-md-8 mx-auto">
                                    <p className="lead b-header__jumbotron-lead" id="findhomebuyer">
                                        Get home buying advice from real buyers
                                    </p>
                                    <div className="d-block justify-content-center align-items-center d-sm-flex">
                                        <Link to="/stories">
                                            <button type="button" className="btn btn-lg btn-primary b-header__btn" id="go-story-btn">
                                                Go to stories
                                            </button>
                                        </Link>
                                    </div>
                                    {/* <form autoComplete="off" onSubmit={handleLocSubmit}>
                                        <div className="form-group">  
                                            <Autocomplete
                                                menuStyle={{
                                                    borderRadius: '27px',
                                                    background: 'white',
                                                    padding: '9px 8px 9px 4px',
                                                    fontSize: '16px',
                                                    position: 'fixed',
                                                    overflow: 'hidden',
                                                    textAlign: 'left',
                                                    maxHeight: '25%',
                                                    boxShadow: '0 30px 60px 0 rgba(108, 104, 130, 0.2), 0 1px 0 0 rgba(23, 13, 78, 0.08)'
                                                }}
                                                getItemValue={(item) => item}
                                                shouldItemRender={(item, value) => item.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                items={all_neighborhood_city_combinations.sort()}
                                                renderItem={(item, isHighlighted) =>
                                                    <div key={item} style={{ 
                                                        background: isHighlighted ? '#6665ff' : 'white',
                                                        color: isHighlighted ? 'white' : '#170d4e',
                                                        fontWeight: 600,
                                                        borderRadius: '42px',
                                                        padding: '10px 0px 10px 32px'
                                                    }}>
                                                        {item}
                                                    </div>
                                                }
                                                renderInput={props => {
                                                    return <input className="form-control" id="mainSubmission" {...props} />
                                                }}
                                                value={value}
                                                onChange={(e) => changeValue(e.target.value)}
                                                onSelect={(val) => changeValue(val)}
                                                wrapperStyle={{width: "100%"}}
                                            />
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <a href="#about" className="btn btn-link btn-arrow">
                        <img src="../../img/ic-arrow-downward-24-px.svg" alt="" className="img-fluid"></img>
                    </a>
                </header>
                <section className="b-section b-section_about h-dotted h-dotted_center ">

                    <a href="#about" className="btn btn-link btn-arrow">
                        <img src="../../img/ic-arrow-downward-24-px.svg" alt="" className="img-fluid"></img>
                    </a>
                    <div className="container ">
                        <div className="block-border-x"></div>
                        <div className="b-section__name" id="about">About us</div>
                        <h2 className="b-section__title">Buying a home doesn’t have to be a mystery</h2>
                        <div className="row align-items-center">
                            <div className="col-lg-4 offset-lg-1 col-md-8 offset-md-2 b-section__text text-md-left">
                                <p className="b-section__text_bold">What if you could see what other people experienced when they bought their home? What if you could hear people's thoughts on their neighborhood before moving there?
                                </p>
                                <div className="text-center">
                                    <img src="../../img/about.jpg" alt=""
                                        srcSet="../../img/about@2x.jpg 2x, ../../img/about@3x.jpg 3x"
                                        className="img-fluid d-lg-none mb-4"></img>
                                </div>
                                <p>Buying a home is likely the biggest purchase of your life—you should feel confident in making such a huge decision.</p>
                                <div>No one knows real estate better than the people who live in the local area. VillaVox pulls back the curtain on what it’s like to buy a home in the neighborhood of your dreams to help you make an informed decision.
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-2 col-md-8 offset-md-2 d-none d-lg-block">
                                <div className="b-section_about__img">
                                    <img src="../../img/about.jpg" alt=""
                                        srcSet="../../img/about@2x.jpg 2x, ../../img/about@3x.jpg 3x"
                                        className="img-fluid "></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="overflow-hidden">
                    <section className="b-section b-section_how container" id="how">
                        <div className="b-section__name">How it works</div>

                        <ol className="row b-section_how__items">

                            <li className="col-md-4 col-sm-6 offset-sm-3 offset-md-0 b-section_how__item">
                                <div className="how-item">
                                    <img src="../../img/70846363.jpg" alt=""
                                        srcSet="../../img/70846363@2x.jpg 2x, ../../img/70846363@3x.jpg 3x"
                                        className="img-fluid how-item__img"></img>
                                    <h6 className="how-item__title">Learn from Real People</h6>
                                    <p className="how-item__text">Read real stories from home buyers in the area where you want to buy.
                                        Hear
                                        their success stories
                                        and what they wish they had known before closing.</p>
                                </div>
                            </li>
                            <li className="col-md-4 col-sm-6 offset-sm-3 offset-md-0 b-section_how__item">
                                <div className="how-item">
                                    <img src="../../img/214927428.jpg" alt=""
                                        srcSet="../../img/214927428@2x.jpg 2x, ../../img/214927428@3x.jpg 3x"
                                        className="img-fluid how-item__img"></img>
                                    <h6 className="how-item__title">Buy with Confidence</h6>
                                    <p className="how-item__text">Take a comprehensive look at a neighborhood’s real estate environment.
                                        Get
                                        realtor recommendations. Save thousands.</p>
                                </div>
                            </li>
                            <li className="col-md-4 col-sm-6 offset-sm-3 offset-md-0 b-section_how__item">
                                <div className="how-item">
                                    <img src="../../img/186692715.jpg" alt=""
                                        srcSet="../../img/186692715@2x.jpg 2x, ../../img/186692715@3x.jpg 3x"
                                        className="img-fluid how-item__img"></img>
                                    <h6 className="how-item__title">Share your Story
                                    </h6>
                                    <p className="how-item__text">Share your unique experience on VillaVox and give back to the
                                        community. </p>
                                </div>
                            </li>
                        </ol>
                        <div id="examples"></div>
                    </section>
                </div>
                
                <div className="b-section b-section_reviews h-dotted h-dotted_left">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-5 col-sm-8 offset-md-0 offset-sm-2 d-flex align-items-center">
                                <img src="../../img/villavox-application-view.png" alt=""
                                    srcSet="../../img/villavox-application-view@2x.png 2x, ../../img/villavox-application-view@3x.png 3x"
                                    className="b-section_reviews__img"></img>
                            </div>
                            <div className="col-md-7 d-flex align-items-center position-relative flex-column">
                                <div id="js-carousel-captions" className="carousel slide" data-ride="carousel" data-interval="10000">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="carousel-caption">
                                                <div className="row">
                                                    <div className="col-md-10 ">
                                                        <div className="">
                                                            <img src="../../img/slider-img-1.png" alt=""
                                                                srcSet="../../img/slider-img-1@2x.png 2x"
                                                                className="img-fluid carousel-item__img"></img>
                                                        </div>
                                                        <div className="carousel-item__name">Will Z.</div>
                                                        <p className="carousel-item__text">”Always ask about special assessments and
                                                            expenses that may affect you in the
                                                            future. Thanks to my agents and a little bit of legwork, I landed my
                                                            top
                                                            pick and avoided a $50,000 disaster. My new neighbor wasn’t so
                                                            lucky!”</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="carousel-caption">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="">
                                                            <img src="../../img/Leah.png" alt=""
                                                                srcSet="../../img/Leah@2x.png 2x, ../../img/Leah@3x.png 3x"
                                                                className="img-fluid carousel-item__img"></img>
                                                        </div>
                                                        <div className="carousel-item__name">Leah R.</div>
                                                        <p className="carousel-item__text">“We found out that the house we wanted was
                                                            priced higher than the home next door, and saved $30,000 by
                                                            underbidding. We even got a $5,000 credit to redo the scratched floors.
                                                            Do your research, and you’ll be able to play hard ball.”</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="carousel-caption">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="">
                                                            <img src="../../img/slider-img-2.png" alt=""
                                                                srcSet="../../img/slider-img-2@2x.png 2x"
                                                                className="img-fluid carousel-item__img"></img>
                                                        </div>
                                                        <div className="carousel-item__name">Andrew V.</div>
                                                        <p className="carousel-item__text">”We were shocked to find out that our offer
                                                            was accepted against multiple bids! Two things that made a difference
                                                            were that our agent personally presented our offer to the listing agent
                                                            and we were approved to do a quick close.”
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row w-100">
                                        <div className="col-md-10">
                                            <ol className="carousel-indicators">
                                                <li data-target="#js-carousel-captions" data-slide-to="0" className="active"></li>
                                                <li data-target="#js-carousel-captions" data-slide-to="1"></li>
                                                <li data-target="#js-carousel-captions" data-slide-to="2"></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-buttons">
                                    <a className="carousel-control-prev" href="#js-carousel-captions" role="button"
                                    data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next" href="#js-carousel-captions" role="button"
                                    data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="b-section b-section_benefits container" id="benefits">
                    <div className="b-section__name">Benefits</div>
                    <h2 className="b-section__title">Take control of your home purchase</h2>
                    <div className="benefit-items">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="benefit-item text-md-right">
                                    <div className="benefit-item__img">
                                        <img src="../../img/user-search-b.svg" alt=""
                                            className=""></img>
                                    </div>
                                    <h6 className="benefit-item__title">Total Transparency</h6>
                                    <div className="benefit-item__text">
                                        Get 100% unbiased real estate advice. VillaVox is not associated with any real estate
                                        agency, so
                                        you get advice straight from the source.
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="benefit-item text-md-center">
                                    <div className="benefit-item__img">
                                        <img src="../../img/pie-chart.svg" alt=""></img>
                                    </div>
                                    <h6 className="benefit-item__title">See the Big Picture</h6>
                                    <div className="benefit-item__text">
                                        Gain exclusive market perspectives. Authentic buying experiences give you a fully scaled look at Seattle’s dynamic real estate market.
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="benefit-item text-md-left">
                                    <div className="benefit-item__img">
                                        <img src="../../img/savings.svg" alt="" className=""></img>
                                    </div>
                                    <h6 className="benefit-item__title">Buy with Confidence</h6>
                                    <div className="benefit-item__text">
                                        Hear the stories, learn negotiation strategies, get agent recommendations, and much more.
                                        When
                                        you’re ready to buy, you’ll know you’ve found the perfect home.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="b-section b-section_join h-dotted h-dotted_footer" id="join">
                    <div className="container">
                        <div className="b-section__name">Join the community</div>
                        <h2 className="b-section__title b-section__title_lg">See Seattle area real estate from the eyes of
                            real
                            homeowners.
                        </h2>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-9 col-10 mx-auto">
                                <div className="text-center mt-md-0 mt-5">
                                    <Link to="/stories">
                                        <button type="button" className="btn btn-lg btn-primary b-header__btn">
                                            Go to stories
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade" id="geaModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Get Early Access!</h5>
                                <button type="button" data-dismiss="modal" className="close" id="closeButton" aria-label="Close">
                                    <img aria-hidden="true" id="closeIcon" src="../../img/close@3x.png"/>
                                </button>
                            </div>
                            <p id="extraTag">Get exclusive access to home buying experiences in Seattle</p>
                            <div className="modal-body">
                                <form id="geaForm" onSubmit={handleSubmit}>
                                    <div className="form-group">  
                                        <input type="email" name="email" className="form-control" id="emailInput" aria-describedby="emailHelp" placeholder="Email"/>
                                        <img id="userImage" src='../../img/user@3x.png' />
                                    </div>
                                    <button type="submit" id="modalSubmit" className="btn btn-primary btn-md">Join</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="b-footer container ">
                    <div className="row">
                        <div className="col-md-4 text-center offset-md-4 mb-5 mb-md-0">
                            <a href="https://www.facebook.com/joinVillaVox/" className="btn btn-link" target="_blank"
                            rel="noopener nofollow">
                                <img src="../../img/facebook.svg" className="" alt="Villavox Facebook page"></img>
                            </a>
                            <a href="https://www.instagram.com/villavox_/" className="btn btn-link" target="_blank"
                            rel="noopener nofollow">
                                <img src="../../img/instagram.svg" className="" alt="Vollavox Instagram page"></img>
                            </a>
                            <a href="https://twitter.com/villavox_/" target="_blank" rel="noopener nofollow" className="btn btn-link">
                                <img src="../../img/twitter.svg" className="" alt="Villavox Twitter profile"></img>
                            </a>
                        </div>
                        <div className="col-md-3 text-md-right text-center offset-md-8 my-1">© 2019 VillaVox</div>
                        <div className="col-md-3 text-md-right text-center offset-md-8 my-1">
                            <a href="/privacy-policy">Privacy Policy</a>
                        </div>
                        <div className="col-md-3 text-md-right text-center offset-md-8 my-1">
                            <a href="/terms-of-use">Terms of Use</a>
                        </div>
                    </div>
                </footer>
            </main>
        </div>
    );
}

export default withRouter(Landing);