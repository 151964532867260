import React, { useState, useContext } from "react";
import MicroModal from "micromodal";
import { store } from "../Store/index.jsx";

const JoinSecondSurveyModal = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const globalState = useContext(store);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    globalState.dispatch({
      type: "secondSurvey",
      secondSurvey: e.target.value,
    });
    MicroModal.close("joinSecondSurveyModal");
    MicroModal.show("joinModal");
    setSelectedOption(null);
  };

  return (
    <div
      className="modal micromodal-slide"
      id="joinSecondSurveyModal"
      aria-hidden="true"
    >
      <div className="modal__overlay" tabIndex="-1">
        <div
          className="modal__container modal__containerXXS"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
        >
          <header className="modal__header">
            <h2 className="modal__title" id="modal-1-title">
              Are you working with an agent?
            </h2>
          </header>
          <main className="modal__content" id="modal-1-content">
            <form className="ml-4">
              <label className="radio-container">
                Yes
                <input
                  type="radio"
                  value="yes"
                  name="work_with_agent"
                  checked={selectedOption === "yes"}
                  onChange={handleOptionChange}
                />
                <span className="checkmark"></span>
              </label>
              <label className="radio-container">
                No
                <input
                  type="radio"
                  value="no"
                  name="work_with_agent"
                  checked={selectedOption === "no"}
                  onChange={handleOptionChange}
                />
                <span className="checkmark"></span>
              </label>
            </form>
          </main>
        </div>
      </div>
    </div>
  );
};

export default JoinSecondSurveyModal;
