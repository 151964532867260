import React, { useState } from "react";
import "./filterBar.css";
import home from "../../img/home@3x.png";
import downArrow from "../../img/small-down@3x.png";
import { ClickAwayListener } from "@material-ui/core";

const TypeSelectDrop = (props) => {
  const [showItems, setshowItems] = useState(false);
  let selectedOption = props.type;

  let handleClick = (e) => {
    e.preventDefault();
    setshowItems((prevState) => !prevState);
    //props.handleType(selectedOption);
  };

  const handleClickAway = () => {
    setshowItems(false);
  };

  let optionChange = (e) => {
    e.preventDefault();
    props.handleType(e);
    setshowItems((prev) => (!prev))
  };

  let options = props.types.map((type) => {
    return (
      <div
        key={type}
        id={type}
        onClick={optionChange}
        className={selectedOption === type ? "selectedOpt" : "regularOption"}
      >
        {type}
      </div>
    );
  });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="dropdown">
        <div
          className="selectButton"
          onClick={handleClick}
          style={{
            backgroundColor: showItems ? "rgba(102,101, 255, 0.1)" : "",
          }}
        >
          <img className="selectImage" src={home}></img>
          <span
            className="selectButtonText"
            style={{ color: showItems ? "#6665ff" : "#170d4e" }}
          >
            {props.renderedType}
          </span>
          <img id="selectArrow" src={downArrow} />
        </div>
        {showItems ? (
          <div
            className="optionsBox"
            style={{ display: "block" }}
          >
            {options}
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default TypeSelectDrop;
