import React, { useState, useContext } from "react";
import MicroModal from "micromodal";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { store } from "../Store/index.jsx";
import CloseIcon from "../../img/close@3x.png";
import Google from "../../img/google@3x.png";
import Facebook from "../../img/facebook@3x.png";

const JoinModal = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const globalState = useContext(store);

  const gprovider = new firebase.auth.GoogleAuthProvider();
  const fprovider = new firebase.auth.FacebookAuthProvider();

  const handleRegister = (e) => {
    setError("");
    e.preventDefault();
    firebase
      .auth()
      .createUserWithEmailAndPassword(username, password)
      .then(function () {
        saveUserInfo(
          firstname,
          lastname,
          null,
          globalState.state.join ? globalState.state.join.firstSurvey : null,
          globalState.state.join ? globalState.state.join.secondSurvey : null
        );
        MicroModal.close("joinModal");
      })
      .catch(function (error) {
        setError(error.message);
      });
  };

  const handleFacebookSignUp = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithPopup(fprovider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        saveUserInfo(
          user.displayName.split(" ").slice(0, -1).join(" "),
          user.displayName.split(" ").slice(-1).join(" "),
          user.photoURL,
          globalState.state.join ? globalState.state.join.firstSurvey : null,
          globalState.state.join ? globalState.state.join.secondSurvey : null
        );
        MicroModal.close("joinModal");
        MicroModal.close("signIn");
      })
      .catch(function (error) {
        setError(error.message);
      });
  };

  const handleGoogleSignUp = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithPopup(gprovider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        saveUserInfo(
          user.displayName.split(" ").slice(0, -1).join(" "),
          user.displayName.split(" ").slice(-1).join(" "),
          user.photoURL,
          globalState.state.join ? globalState.state.join.firstSurvey : null,
          globalState.state.join ? globalState.state.join.secondSurvey : null
        );
        MicroModal.close("joinModal");
        MicroModal.close("signIn");
      })
      .catch(function (error) {
        setError(error.message);
      });
  };

  const saveUserInfo = (
    firstName,
    lastName,
    photoURL,
    joinFirstSurvey,
    joinSecondSurvey
  ) => {
    const user = firebase.auth().currentUser;
    firebase.database().ref(`Users/${user.uid}`).set({
      firstName,
      lastName,
      photoURL,
      joinFirstSurvey,
      joinSecondSurvey,
    });
  };

  const isViewPostLimited = () => {
    const viewCount = parseInt(localStorage.getItem('view_post_count'), 10) || 0;
    return viewCount >= 4;
  }

  const modalContent = () => {
    return (
      <div
          className="modal__container modal__containerXL"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
        >
          <header className="modal__header">
            <h2 className="modal__title" id="modal-1-title">
              Join us!
            </h2>
          </header>
          <main className="modal__content" id="modal-1-content">
            <p id="joinModalText">
              Get access to home buying stories in your neighborhood
            </p>
            <form onSubmit={(e) => handleRegister(e)}>
              <div className="form-group">
                <input
                  name="firstname"
                  className="form-control nameSignInInput"
                  placeholder="First Name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  name="lastname"
                  className="form-control nameSignInInput"
                  placeholder="Last Name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  name="username"
                  className="form-control emailSignInInput"
                  placeholder="Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control passwordSignInInput passwordBox"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  id="registerButton"
                  className="btn btn-primary"
                >
                  Join
                </button>
              </div>
              {error != "" && (
                <div className="form-group">
                  <p className="errorMessage">{error}</p>
                </div>
              )}
            </form>
          </main>
          <footer className="modal__footer">
            {!isViewPostLimited() && (
            <button
              className="closeButton"
              data-micromodal-close
              aria-label="Close this dialog window"
            >
              <img
                aria-hidden="true"
                id="closeIcon"
                src={CloseIcon}
                data-micromodal-close
              />
            </button>
            )}
            <p className="or-bar">
              <span>OR</span>
            </p>
            <div className="thirdPartyContainer">
              <img className="thirdPartyIcon" src={Facebook} />
              <button
                className="thirdPartyButton"
                onClick={(e) => handleFacebookSignUp(e)}
              >
                Facebook
              </button>
            </div>
            <div className="thirdPartyContainer">
              <img className="thirdPartyIcon" src={Google} />
              <button
                className="thirdPartyButton"
                onClick={(e) => handleGoogleSignUp(e)}
              >
                Google
              </button>
            </div>
          </footer>
          <div id="ackBox">
            <p id="ackText">
              By continuing, I agree that I am at least 18 years old and have
              read the terms of service and privacy policy.
            </p>
            <br />
          </div>
          <p>
            <span id="alreadyMem">Already a Member? </span>
            <a
              id="redirectText"
              href="#"
              onClick={() => {
                MicroModal.close("joinModal");
                MicroModal.show("signIn");
                setError("");
              }}
            >
              Sign In
            </a>
          </p>
        </div>
    );
  }

  return (
    <div className="modal micromodal-slide" id="joinModal" aria-hidden="true">
      {isViewPostLimited() ? (
        <div className="modal__overlay" tabIndex="-1">
          {modalContent()}
        </div>
      ) : (
        <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
          {modalContent()}
        </div>
      )}
    </div>
  );
};

export default JoinModal;
