import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import "./filterBar.css";
import dollar from "../../img/round-dollar@3x.png";
import downArrow from "../../img/small-down@3x.png";
import toggle from "../../img/o@3x.png";
import clearIcon from "../../img/i-remove@3x.png";
import { ClickAwayListener } from "@material-ui/core";

const PriceSelectDrop = ({
  handlePriceLow,
  handlePriceHigh,
  low,
  high,
  updateSearch,
}) => {
  const formatter = new Intl.NumberFormat();
  const [showItems, setshowItems] = useState(false);
  const [toggleLow, setToggleLow] = useState(0);
  const [toggleHigh, setToggleHigh] = useState(200);
  const [isEditingLow, setIsEditingLow] = useState(false);
  const [isEditingHigh, setIsEditingHigh] = useState(false);
  const MAX_VALUE = 2000000;

  useEffect(() => {
    setToggleLow(Math.ceil(low / 10000));
  }, [low]);

  useEffect(() => {
    setToggleHigh(Math.ceil(high / 10000));
  }, [high]);

  let handleClick = (e) => {
    e.preventDefault();
    setshowItems((prevState) => !prevState);
  };

  const handleClickAway = () => {
    setshowItems(false);
  };

  let handleReset = () => {
    setToggleLow(0);
    setToggleHigh(200);
    handlePriceLow(0);
    handlePriceHigh(MAX_VALUE);
  };

  let handleSave = (e) => {
    setshowItems((prevState) => !prevState);
    updateSearch(e);
  };

  // Sets the position of the toggles and the price of the houses due to drag
  let handleToggleLow = (e, data) => {
    let lowPrice = Math.ceil(data.x * 10000);
    if (lowPrice < 0) lowPrice = 0;
    handlePriceLow(lowPrice);
    setToggleLow(data.x);
  };
  let handleToggleHigh = (e, data) => {
    let highPrice = Math.ceil(data.x * 10000);
    if (highPrice > MAX_VALUE) highPrice = MAX_VALUE;
    handlePriceHigh(highPrice);
    setToggleHigh(data.x);
  };

  // Sets the position of the toggles due to user input price
  let setToggleLowWithInput = (e) => {
    let newToggle = e;
    if (e < 0) {
      newToggle = 0;
    } else if (e > toggleHigh) {
      newToggle = toggleHigh - 4;
    }
    setToggleLow(newToggle);
  };
  let setToggleHighWithInput = (e) => {
    let newToggle = e;
    if (e > 200) {
      newToggle = 200;
    } else if (e < toggleLow) {
      newToggle = toggleLow + 4;
    }
    setToggleHigh(newToggle);
  };

  let optionBox = (
    <div
      className="optionsBox"
      id="priceSelectBox"
      style={{ display: showItems ? "block" : "none" }}
    >
      <div className="minMaxPrices">
        <div className="priceContainer">
          <p className="titlePrice">Min price</p>
          <div className="selectButton priceBox">
            <img className="selectImage priceImage" src={dollar}></img>
            {isEditingLow ? (
              <input
                className="priceInput"
                type="number"
                value={low}
                onChange={(e) => handlePriceLow(e.target.value)}
                onBlur={(e) => {
                  setIsEditingLow(false);
                  setToggleLowWithInput(e.target.value / 5000);
                }}
              />
            ) : (
              <input
                className="priceInput"
                type="text"
                value={formatter.format(low)}
                onFocus={() => setIsEditingLow(true)}
                readOnly
              />
            )}
          </div>
        </div>
        <div className="priceContainer">
          <p className="titlePrice">Max price</p>
          <div className="selectButton priceBox">
            <img className="selectImage priceImage" src={dollar}></img>
            {isEditingHigh ? (
              <input
                className="priceInput"
                type="number"
                value={high}
                onChange={(e) => handlePriceHigh(e.target.value)}
                onBlur={(e) => {
                  setIsEditingHigh(false);
                  setToggleHighWithInput(e.target.value / 5000);
                }}
              />
            ) : (
              <input
                className="priceInput"
                type="text"
                value={formatter.format(high)}
                onFocus={() => setIsEditingHigh(true)}
                readOnly
              />
            )}
          </div>
        </div>
      </div>

      <div className="toggleBarContainer">
        <Draggable
          axis="x"
          onDrag={(e, data) => handleToggleLow(e, data)}
          bounds={{ left: -2, right: toggleHigh }}
          position={{ x: toggleLow, y: 0 }}
          defaultPosition={{ x: toggleLow, y: 0 }}
        >
          <div
            className="toggleBall"
            style={{ backgroundImage: `url(${toggle})` }}
          />
        </Draggable>
        <div
          className="toggleBar"
          style={{
            position: "absolute",
            left: toggleLow + 24,
            width: toggleHigh - toggleLow + 24,
          }}
        ></div>
        <Draggable
          axis="x"
          onDrag={(e, data) => handleToggleHigh(e, data)}
          bounds={{ left: toggleLow, right: 200 }}
          position={{ x: toggleHigh, y: 0 }}
          defaultPosition={{ x: toggleHigh, y: 0 }}
        >
          <div
            className="toggleBall"
            style={{ backgroundImage: `url(${toggle})` }}
          />
        </Draggable>
        <div className="toggleBar grayBar"></div>
      </div>

      <div className="priceLine"></div>

      <div className="bottomTab">
        <div id="clearButtonBox" onClick={() => handleReset()}>
          <img id="clearFilterX" src={clearIcon}></img>
          <p id="cleartext">Reset filter</p>
        </div>
        <button onClick={(e) => handleSave(e)} className="saveButton">
          Save
        </button>
      </div>
    </div>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="dropdown">
        <div
          className="selectButton"
          onClick={handleClick}
          style={{
            backgroundColor: showItems ? "rgba(102,101, 255, 0.1)" : "",
          }}
        >
          <img className="selectImage" src={dollar}></img>
          <span
            className="selectButtonText"
            style={{ color: showItems ? "#6665ff" : "#170d4e" }}
          >
            Sold Price
          </span>
          <img id="selectArrow" src={downArrow} />
        </div>
        {optionBox}
      </div>
    </ClickAwayListener>
  );
};

export default PriceSelectDrop;
