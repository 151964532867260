import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="WordSection1 container" >

        <a className="navbar-brand mt-3 mb-5" href="/">
            <img src="../img/logo-black.png" alt="Villavox"
                className=""/>
        </a>

        <h1 className="mb-3" className="spanStyleTwo">Privacy Policy</h1>


        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">VillaVox
        (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the 
        <a href="/"> https://villavox.com</a> website (the &quot;Service&quot;).</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">This
        page informs you of our policies regarding the collection, use, and disclosure
        of personal data when you use our Service and the choices you have associated
        with that data.&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        use your data to provide and improve the Service. By using the Service, you
        agree to the collection and use of information in accordance with this policy.
        Unless otherwise defined in this Privacy Policy, terms used in this Privacy
        Policy have the same meanings as in our Terms and Conditions, accessible from
        <a href="/"> https://villavox.com</a></span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Information Collection And Use</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        collect several different types of information for various purposes to provide
        and improve our Service to you.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleFive">Types of Data Collected</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Personal
        Data</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">While
        using our Service, we may ask you to provide us with certain personally
        identifiable information that can be used to contact or identify you
        (&quot;Personal Data&quot;). Personally identifiable information may include,
        but is not limited to:</span></p>

        <ul >
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">Email address</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">First and last name</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">Cookies and Usage Data</span></li>
        </ul>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Usage
        Data</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        may also collect information how the Service is accessed and used (&quot;Usage
        Data&quot;). This Usage Data may include information such as your computer's
        Internet Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit, the time
        spent on those pages, unique device identifiers and other diagnostic data.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Tracking
        &amp; Cookies Data</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        use cookies and similar tracking technologies to track the activity on our
        Service and hold certain information.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Cookies
        are files with small amount of data which may include an anonymous unique
        identifier. Cookies are sent to your browser from a website and stored on your
        device. Tracking technologies also used are beacons, tags, and scripts to
        collect and track information and to improve and analyze our Service.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">You
        can instruct your browser to refuse all cookies or to indicate when a cookie is
        being sent. However, if you do not accept cookies, you may not be able to use
        some portions of our Service.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Examples
        of Cookies we use:</span></p>

        <ul >
            <li className="MsoNormal" className="spanStyleFour"><b><span className="spanStyleThree">Session Cookies.</span></b>
            <span className="spanStyleThree">&nbsp;We use Session Cookies to operate our Service.</span></li>
            <li className="MsoNormal" className="spanStyleFour"><b><span className="spanStyleThree">
                Preference Cookies.</span></b>
            <span className="spanStyleThree">&nbsp;We use Preference Cookies to
            remember your preferences and various settings.</span></li>
            <li className="MsoNormal" className="spanStyleFour"><b><span className="spanStyleThree">
                Security Cookies.</span></b><span className="spanStyleThree">
                    &nbsp;We use Security Cookies for security purposes.
                </span></li>
        </ul>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Use of Data</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">VillaVox
        uses the collected data for various purposes:</span></p>

        <ul >
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To provide and maintain the Service</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To notify you about changes to our Service</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To allow you to participate in interactive features of our
            Service when you choose to do so</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To provide customer care and support</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To provide analysis or valuable information so that we can
            improve the Service</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To monitor the usage of the Service</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To detect, prevent and address technical issues</span></li>
        </ul>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Transfer Of Data</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Your
        information, including Personal Data, may be transferred to — and maintained on
        — computers located outside of your state, province, country or other
        governmental jurisdiction where the data protection laws may differ than those
        from your jurisdiction.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">If
        you are located outside United States and choose to provide information to us,
        please note that we transfer the data, including Personal Data, to United
        States and process it there.</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Your
        consent to this Privacy Policy followed by your submission of such information
        represents your agreement to that transfer.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">VillaVox
        will take all steps reasonably necessary to ensure that your data is treated
        securely and in accordance with this Privacy Policy and no transfer of your
        Personal Data will take place to an organization or a country unless there are
        adequate controls in place including the security of your data and other
        personal information.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Disclosure Of Data</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleFive">Legal Requirements</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">VillaVox
        may disclose your Personal Data in the good faith belief that such action is
        necessary to:</span></p>

        <ul >
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To comply with a legal obligation</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To protect and defend the rights or property of VillaVox</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To prevent or investigate possible wrongdoing in connection
            with the Service</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To protect the personal safety of users of the Service or
            the public</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">To protect against legal liability</span></li>
        </ul>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Security Of Data</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">The
        security of your data is important to us, but remember that no method of
        transmission over the Internet, or method of electronic storage is 100% secure.
        While we strive to use commercially acceptable means to protect your Personal
        Data, we cannot guarantee its absolute security.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Service Providers</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        may employ third party companies and individuals to facilitate our Service
        (&quot;Service Providers&quot;), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our Service
        is used.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">These
        third parties have access to your Personal Data only to perform these tasks on
        our behalf and are obligated not to disclose or use it for any other purpose.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleFive">Analytics</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        may use third-party Service Providers to monitor and analyze the use of our
        Service.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <ul >
            <li className="MsoNormal" className="spanStyleFour"><b><span className="spanStyleThree">
                Google Analytics</span></b><span className="spanStyleThree"><br />
            Google Analytics is a web analytics service offered by Google that tracks
            and reports website traffic. Google uses the data collected to track and
            monitor the use of our Service. This data is shared with other Google
            services. Google may use the collected data to contextualize and
            personalize the ads of its own advertising network.</span></li>
            <li className="MsoNormal" className="spanStyleFour"><span className="spanStyleThree">
            You can opt-out of having made
            your activity on the Service available to Google Analytics by installing the
            Google Analytics opt-out browser add-on. The add-on prevents the Google
            Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information
            with Google Analytics about visits activity.For more information on the privacy
            practices of Google, please visit the Google Privacy &amp; Terms web
            page:&nbsp;<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></span></li>
        </ul>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Links To Other Sites</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Our
        Service may contain links to other sites that are not operated by us. If you
        click on a third party link, you will be directed to that third party's site.
        We strongly advise you to review the&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Privacy
        Policy of every site you visit.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        have no control over and assume no responsibility for the content, privacy
        policies or practices of any third party sites or services.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Children's Privacy</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">Our
        Service does not address anyone under the age of 18 (&quot;Children&quot;).</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        do not knowingly collect personally identifiable information from anyone under
        the age of 18. If you are a parent or guardian and you are aware that your
        Children has provided us with Personal Data, please contact us. If we become
        aware that we have collected Personal Data from children without verification
        of parental consent, we take steps to remove that information from our servers.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Changes To This Privacy Policy</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        may update our Privacy Policy from time to time. We will notify you of any
        changes by posting the new Privacy Policy on this page.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">We
        will let you know via email and/or a prominent notice on our Service, prior to
        the change becoming effective and update the &quot;effective date&quot; at the
        top of this Privacy Policy.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">You
        are advised to review this Privacy Policy periodically for any changes. Changes
        to this Privacy Policy are effective when they are posted on this page.</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleTwo">Contact Us</span></p>

        <p className="MsoNormal"><span className="spanStyleOne">&nbsp;</span></p>

        <p className="MsoNormal"><span className="spanStyleThree">If
        you have any questions about this Privacy Policy, please contact us: <a href="mailto:hello@villavox.com">hello@villavox.com</a></span><span
        className="spanStyleSix"> &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;</span></p>

        <p className="MsoNormal">&nbsp;</p>

        </div>
    );
}

export default PrivacyPolicy;