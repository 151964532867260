export default {
  firebaseConfig: {
    apiKey: 'AIzaSyBwkudAzCtauiYrcaIbfRmD6vrIGN00Md8',

    authDomain: 'villavox-a5e76.firebaseapp.com',

    databaseURL: 'https://villavox-a5e76.firebaseio.com',

    projectId: 'villavox-a5e76',

    storageBucket: 'villavox-a5e76.appspot.com',

    messagingSenderId: '756137856272',

    appId: '1:756137856272:web:d7d752ab5e09c453c62995',

    measurementId: 'G-5XF4FLW8WK',

    storageBucket: 'gs://villavox-a5e76.appspot.com',
  },
};
