import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker.jsx';
import { GOOGLE_API_KEY, pointCoords } from '../const';

import './GoogleMap.css';

const customMapStyle = require('./customstyle.json');


const GoogleMap = (props) => {
  // Set center via props based on filter location
  const [center, setCenter] = useState({ lat: 47.578439, lng: -122.200407 });
  const [zoom, setZoom] = useState(10);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showOriginalButton, setShowOriginalButton] = useState(false);

  const handleMarkerClick = (id) => {
    const marker = pointCoords.find(coord => coord.areaKey === id);
    setSelectedMarker(marker);
    props.onMarkerSelected(marker);
    if (id) {
      setShowOriginalButton(true);
    }
  };

  const createMarkers = () => {
    return pointCoords.map(area => {
      return (
        <Marker
          key={area.areaKey}
          id={area.areaKey} 
          lat={area.lat}
          lng={area.lng} 
          onClick={handleMarkerClick}
          selected={selectedMarker && selectedMarker.areaKey === area.areaKey ? true : false}
        />
      )
    });
  }

  const allMarkers = createMarkers();

  const handleOriginalMap = () => {
    setCenter({ lat: 47.578439, lng: -122.200407 });
    setZoom(10);
    setSelectedMarker(null);
    props.onMarkerSelected(null);
    setShowOriginalButton(false);
  };

  const handleMapChange = (value) => {
    setCenter(value.center);
    setZoom(value.zoom);
    setShowOriginalButton(value.center.lat !== 47.578439 || value.center.lng !== -122.200407 || value.zoom !== 10);
  };

  return (
    <div className="map-wrapper">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        center={center}
        zoom={zoom}
        options={{ styles: customMapStyle }}
        onChange={(value) => handleMapChange(value)}
      >
      {allMarkers}
      </GoogleMapReact>
      {showOriginalButton && (
        <button
          className="original-map-btn"
          onClick={handleOriginalMap}
        >
          <img
              src="img/arrow-left-white.png"
              srcSet="img/arrow-left-white@2x.png 2x, img/arrow-left-white@3x.png 3x"
              style={{ marginRight: 5 }}
            />
          Original map
        </button>
      )}
    </div>
  );
};

export default GoogleMap;
