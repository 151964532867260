import React, { useState } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'

import Landing from "./Landing/Landing.jsx";
import Dashboard from "./Dashboard/Dashboard.jsx";
import Share from "./sharePage/Share.jsx";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy.jsx";
import TermsOfUse from "./TermsOfUse/TermsOfUse.jsx";
import { StateProvider } from "./Store/index.jsx";

const App = () => {
  //let [startLoc, changeStartLoc] = useState('');

  return (
    <StateProvider>
      <BrowserRouter>
        <Route path="/" exact component={() => <Landing />} />
        <Route path="/stories/:uniqueUrl?" component={() => <Dashboard />} />
        <Route path="/share" component={() => <Share />} />
        <Route path="/privacy-policy" component={() => <PrivacyPolicy />} />
        <Route path="/terms-of-use" component={() => <TermsOfUse />} />
      </BrowserRouter>
    </StateProvider>
  );
};

export default App;
