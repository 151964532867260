import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import ForumRounded from '@material-ui/icons/ForumRounded';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import PreviewImageModal from './PreviewImageModal.jsx';

// Dollar formatter
const formatter = Intl.NumberFormat();

const PreviewPost = ({ keyCount, wordCap, characterCount, handleReadMore, post, postImages, posterImages }) => {
  const { title, offer, sold, list, experience, commentCount } = post;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window && window.removeEventListener('resize', updateWidth);
  }, []);
  const updateWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const getPosterImage = () => {
    if (post['poster name'] != -1) {
      return posterImages.find((posterImage) => posterImage.includes(post.PostID) && posterImage.includes(post['poster name']));
    }
    return null;
  };

  return (
    <div key={keyCount} onClick={() => handleReadMore(keyCount)}>
      <div className="line" />
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: getPosterImage() ? '15% auto 10%' : '0 auto 10%', textAlign: 'left' }}>
          <div style={{ display: 'flex !important', textAlign: 'center' }}>
            {post['poster name'] != -1 && (
            <p className="poster-name" style={{ marginBottom: getPosterImage() ? '0' : '1rem' }}>{post['poster name']}</p>
            )}
            {getPosterImage() && (
            <img src={getPosterImage()} alt="poster" className="poster-image" />
            )}
          </div>
          <div className="prevPostTitle" style={{ alignSelf: 'center', alignText: 'left', marginLeft: (getPosterImage() || post['poster name'] != -1) ? 10 : 0 }}>
            {title}
          </div>
          {commentCount > 0
            ? (
              <div>
                <IconButton style={{ margin: '7.5px 20px 0 0' }}>
                  <Badge max={9} badgeContent={commentCount}>
                    <ForumRounded style={{ color: '#6665FF', fontSize: '22px' }} />
                  </Badge>
                </IconButton>
              </div>
            ) : null}
        </div>
        <p style={{ marginTop: 3 }}>{`${experience.substring(0, characterCount + wordCap - 1)}...`}</p>
      </div>
      <div className="secondaryRow">
        {screenWidth > 1000
          ? <Button className="readMore" onClick={() => handleReadMore(keyCount)}>Read More</Button>
          : <Button className="readMore" onClick={() => handleReadMore(keyCount)}>Read</Button>}
        <div className="extraPrevInfo">
          { list !== '-1' && (
            <p className="singleTrait">
              List:
              <span className="traitValue">{`$${formatter.format(list)}`}</span>
            </p>
          ) }
          { offer !== '-1' && (
            <p className="singleTrait">
              Offer:
              <span className="traitValue">{`$${formatter.format(offer)}`}</span>
            </p>
          ) }
          { sold !== '-1' && (
            <p className="singleTrait">
              Sold:
              <span className="traitValue">{`$${formatter.format(sold)}`}</span>
            </p>
          ) }
        </div>

      </div>
      {postImages !== null && <PreviewImageModal postImages={postImages} id={keyCount} />}
    </div>
  );
};

export default PreviewPost;
